export const credentials = {
    labels: {
        my_credentials: "Minhas credenciais",
        date: "Data",
        hour: "Hora",
        event: "Evento",
        type: "Tipo",
        status: "Status",
        empty: "Você não possui credenciamentos.",
    },
    types: {
        cosplay: "Cosplay",
        media: "Imprensa",
        influencer: "Influencer",
        shopkeeper: "Lojista",
        artist_alley: "Artist alley",
    },
    status: {
        pending: "Pendente",
        approval: "Aprovado",
        disapproved: "Reprovado",
    },
};
  