import { get, post, put } from "../rest";
import { getLanguage } from "@/common/utils/texts";
import { getUser } from "@/common/services/users";
import { UserProfileType } from "@/common/schemas";

function getHeaders() {
  const user = getUser();
  return {
    "Content-Type": "application/json",
    "language": getLanguage(),
    "token": user?.token,
    "id": user?.id,
  };
}

export async function userLogin(data: { login: string; password: string }) {
  return await post("admin/login", data, { language: getLanguage() });
}

export async function userProfile(id: string) {
  return await get(`member/${id}`, getHeaders());
}

export async function userRecover(email: string) {
  return await post("member/recover", { email }, getHeaders());
}

export async function userRecoverHash(hash: string) {
  return await get(`member/recover/hash/${hash}`, getHeaders());
}

export async function userUpdatePass(data: { hash: string; password: string }) {
  return await post("member/change/password", data, getHeaders());
}

export async function userRegister(data: {
    name: string;
    birthdate: string;
    email: string;
    login: string;
    password: string
}) {
  return await post("member/register", data, getHeaders());
}

export async function userProfileUpdate(id: string, data: UserProfileType) {
  return await put(`member/profile/${id}`, data, getHeaders());
}

export async function userFetchAvatar(id: string, type: "small" | "large" = "large") {
  return await get(`common/avatar/${id}/${type}`, getHeaders());
}

export async function userAddAvatar(id: string, file: string) {
  return await post(`common/avatar/${id}`, { file }, getHeaders());
}

export async function validToken() {
  return await get("valid/token", getHeaders());
}
