<script setup lang="ts">
import { ref } from "vue";
import { BaseInput, BaseSelect } from '@/components';
import { maskDateBR, getDateISO, getDate } from "@/common/utils/dates";

import type { UserType, ProfileType } from "@/common/types/members";

const emit = defineEmits(["update:user", "update:profile", "back", "save:values"]);

const props = defineProps<{
    user: UserType
    profile?: ProfileType
}>();

const types = [
    { label: "Artist Alley", value: "alley"},
    { label: "Cosplay", value: "cosplay"},
    { label: "Influencer", value: "influencer"},
    { label: "Imprensa", value: "media"},
    { label: "Lojista", value: "shopkeeper"},
    { label: "Jurado", value: "jurors"},
];

const activeOptions = [
    { label: "Ativo", value: 1},
    { label: "Inativo", value: 0},
];

const showPassword = ref(false);

function updateUser(key: string, value: string) {
    const payload = {
        ...props.user,
        [key]: value,
    };

    emit("update:user", payload);
}

function updateProfile(key: string, value: string) {
    const payload = {
        ...props.profile,
        [key]: value,
    };

    emit("update:profile", payload);
}
</script>

<template>
    <div class="form">
        <section>
            <h4>DADOS DE ACESSO</h4>
            <base-select
                label="Tipo de Usuário"
                :selected="user.type"
                :options="types"
                placeholder="Informe o tipo de usuário"
                @change:value="updateUser('type', $event)"
            />
            <base-select
                label="Status"
                :selected="user.active"
                :options="activeOptions"
                placeholder="Informe o tipo de usuário"
                @change:value="updateUser('active', $event)"
            />
            <base-input
                name="login"
                label="Login"
                :value="user.login"
                placeholder="Login de usuário"
                background="white"
                @update:value="updateUser('login', $event)"
            />
            <base-input
                name="email"
                label="E-mail"
                :value="user.email"
                placeholder="E-mail do usuário"
                background="white"
                @update:value="updateUser('email', $event)"
            />
            <base-input
                v-if="user.password !== undefined"
                name="password"
                label="Senha de Acesso"
                :value="user.password"
                :sufix="true"
                :sufix-click="true"
                :type="showPassword ? 'text' : 'password'"
                placeholder="Senha do usuário"
                background="white"
                @update:value="updateUser('password', $event)"
                @click:sufix="showPassword = !showPassword"
            >
                <template v-slot:sufix>
                    <img v-if="showPassword" src="@/assets/icons/black/lock-open.png" />
                    <img v-else src="@/assets/icons/black/lock-closed.png" />
                </template>
            </base-input>
        </section>

        <section class="separate" />

        <section v-if="profile">
            <h4>DADOS DO PERFIL</h4>
            <base-input
                label="Nome"
                name="first_name"
                :value="profile.first_name"
                placeholder="Ex: Maria"
                background="white"
                @update:value="updateProfile('first_name', $event)"
            />
            <base-input
                label="Sobrenome"
                name="last_name"
                :value="profile.last_name"
                placeholder="Ex: Silva Pereira"
                background="white"
                @update:value="updateProfile('last_name', $event)"
            />
            <base-input
                label="Nome Social"
                name="social_name"
                :value="profile.social_name"
                placeholder="Nome Social"
                background="white"
                @update:value="updateProfile('social_name', $event)"
            />
            <base-input
                label="Aniversário"
                name="birthdate"
                :value="profile.birthdate"
                placeholder="Data de Nascimento"
                :max-length="10"
                background="white"
                @update:value="updateProfile('birthdate', maskDateBR($event))"
            />
        </section>
        <section class="actions">
            <button class="back" @click="emit('back')">
                VOLTAR
            </button>
            <button class="save" @click="emit('save:values')">
                SALVAR
            </button>
        </section>
    </div>
</template>

<style lang="scss" scoped>
.form {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
    gap: 8px;

    section {
        display: flex;
        width: 98%;
        flex-direction: column;
        gap: 15px;
    }

    .separate {
        height: 30px;
        border-block-start: 1px solid black;
        margin-block-start: 30px;
    }

    .actions {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-block-start: 15px;
        gap: 15px;

        button {
            cursor: pointer;
            width: fit-content;
            height: 45px;
            padding-block: 5px;
            padding-inline: 20px;
            border: 1px solid transparent;
            border-radius: 8px;
            font-weight: bold;
        }

        .back {
            background: #e8e8e8;
            
            &:hover {
                background: #bebebe;
            }
        }

        .save {
            background: var(--primary);
            padding-inline: 35px;

            &:hover {
                background: var(--primary-hover);
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .form {
        width: 99%;
    }
}
</style>