<script setup lang="ts">
import store from "@/store";
import { ref, computed, onBeforeMount, onMounted } from "vue";

import { getImage } from "@/common/utils/images";
import { getTranslate } from "@/common/utils/texts";
import { toast } from "@/common/utils/toast";
import { maskDateBR, getDateISO, getDate } from "@/common/utils/dates";
import { goToLink } from "@/common/utils/link";
import { BaseInput, BaseButton, BaseAvatar, BaseSelect, BaseTextarea } from "@/components";

import { getUser, setProfile } from "@/common/services/users";
import { useUserProfile, useUserProfileUpdate } from "@/common/hooks/users";

import type { UserProfileType } from "@/common/schemas";

import { useI18n } from "vue-i18n";
import { userAddAvatar, userFetchAvatar } from "@/common/api/rest/users";

const { tm } = useI18n();

const { mutateAsync: fetchProfile } = useUserProfile();
const { mutateAsync: putProfile } = useUserProfileUpdate();

const genderOptions = computed(() => {
    const genders = tm("user.gender");
    return Object.entries(genders)
        .reduce((acc: { label: string; option: string }[], [key, value]) => {
            acc.push({
                label: value,
                value: key
            });
            return acc;
        }, []);
});

const profileDefault = {
    "avatar": null,
    "first_name": null,
    "last_name": null,
    "social_name": null,
    "birthdate": null,
    "gender": null,
    "rg": null,
    "phone": null,
    "whatsapp": null,
    "instagram": null,
    "description": null
};

const user = computed(() => store.getters.user || {});
const payload = ref<UserProfileType>({ ...profileDefault });
const file = ref<string | null>(null);

function redimensionarImagem(file: File, maxWidth: number, callback) {
    const img = new Image();

    img.onload = function() {
        let width = img.width;
        let height = img.height;

        // Calcula a proporção de redimensionamento com base na largura máxima
        const ratio = maxWidth / width;

        // Calcula a altura com base na proporção de redimensionamento
        height = height * ratio;

        // Cria um canvas para redimensionar a imagem
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        canvas.width = maxWidth;
        canvas.height = height;

        // Desenha a imagem no canvas redimensionado
        ctx.drawImage(img, 0, 0, maxWidth, height);

        // Converte o canvas de volta para um Blob
        canvas.toBlob(function(blob) {
            // Chama a função de callback com o Blob resultante
            callback(blob);
        }, file.type);
    };

    // Carrega a imagem
    img.src = URL.createObjectURL(file);
}

async function convertFileToBase64(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

async function selectFile(event) {
    const files = event.target.files[0];
    if (!files) return;
    
    const reader = new FileReader();
    reader.readAsDataURL(files);

    redimensionarImagem(files, 1024, async(resized: File) => {
        const base64String = await convertFileToBase64(resized);
    
        file.value = base64String;
    
        store.commit("SET_AVATAR", file.value);
    })
}

function fetchAvatar(id: string) {
    userFetchAvatar(id)
        .then((response) => {
            if (response.success) {
                store.commit("SET_AVATAR", response.data.file);
            }
        });
}

function getProfile() {
    if (user.value)
        fetchProfile(user.value.id)
        .then(async (result) => {
            const { success, data } = result;
            if (success) {
                fetchAvatar(data.id);
                payload.value = {
                    ...data.profile,
                    birthdate: getDate(data.profile?.birthdate, ""),
                };
                setProfile(payload.value);
            }
        })
        .catch(() => {
            toast({ message: "Erro ao carregar o perfil", type: "error" });
        });
}

async function updateProfile() {
    store.commit("SET_LOADING", true);
    if (file.value)
        await userAddAvatar(user.value.id, file.value)
            .then((response) => {
                if (response.success)
                    file.value = null;
            });

    putProfile({
        id: user.value.id,
        payload: payload.value,
    },{
        onSuccess: (response) => {
            const { success, data } = response;

            if (success) {
                getProfile();
                toast({
                    message: data.message,
                    type: "success",
                });
                store.commit("SET_LOADING", false);
            } else {
                toast({
                    message: data.message,
                    type: "error",
                });
                store.commit("SET_LOADING", false);
            }
        },
        onError: () => {
            toast({
                message: "error.bad_request",
                type: "error",
            });
            store.commit("SET_LOADING", false);
        }
    });
}

onBeforeMount(() => {
  getUser();
});

onMounted(() => {
    getProfile();
});
</script>

<template>
  <section class="profile-page">
    <div class="avatar">
        <div class="content">
            <BaseAvatar
                :image="store.getters.avatar"
                size="200px"
            />
            <div class="send-file">
                <input id="avatar" type="file" @change="selectFile" accept="image/*">
                <label for="avatar" class="select-file">
                    {{ getTranslate({ text: "user.labels.change_picture" }) }}
                </label>
            </div>
        </div>
    </div>
    <div class="form">
        <div class="group">
            <BaseInput
                :value="payload.first_name"
                :label="getTranslate({ text: 'user.labels.first_name' })"
                :placeholder="getTranslate({ text: 'user.placeholders.first_name' })"
                color="#000"
                @update:value="payload.first_name = $event"
            />
            <BaseInput
                :value="payload.last_name"
                :label="getTranslate({ text: 'user.labels.last_name' })"
                :placeholder="getTranslate({ text: 'user.placeholders.last_name' })"
                color="#000"
                @update:value="payload.last_name = $event"
            />
        </div>
        <BaseInput
            :value="payload.social_name"
            :label="getTranslate({ text: 'user.labels.artistic_name' })"
            :placeholder="getTranslate({ text: 'user.placeholders.artistic_name' })"
            color="#000"
            @update:value="payload.social_name = $event"
        />
        <div class="group">
            <BaseSelect
                :label="getTranslate({ text: 'user.labels.gender' })"
                :selected="payload.gender"
                :placeholder="payload.gender"
                :options="genderOptions"
                color="#000"
                background="#e8e8e8"
                background-active="#e8e8e8"
                @change="(payload.gender = $event)"
            />
            <BaseInput
                :value="payload.birthdate"
                :label="getTranslate({ text: 'user.labels.birthdate' })"
                :placeholder="getTranslate({ text: 'user.placeholders.birthdate' })"
                color="#000"
                @update:value="payload.birthdate = maskDateBR($event)"
            />
            <BaseInput
                :value="payload.rg"
                :label="getTranslate({ text: 'user.labels.rg' })"
                :placeholder="getTranslate({ text: 'user.placeholders.rg' })"
                color="#000"
                @update:value="payload.rg = $event"
            />
        </div>
        <div class="group">
            <BaseInput
                :value="payload.phone"
                :label="getTranslate({ text: 'user.labels.phone' })"
                :placeholder="getTranslate({ text: 'user.placeholders.phone' })"
                color="#000"
                @update:value="payload.phone = $event"
            />
            <BaseInput
                :value="payload.whatsapp"
                :label="getTranslate({ text: 'user.labels.whatsapp' })"
                :placeholder="getTranslate({ text: 'user.placeholders.whatsapp' })"
                color="#000"
                @update:value="payload.whatsapp = $event"
            />
            <BaseInput
                :value="payload.instagram"
                :label="getTranslate({ text: 'user.labels.instagram' })"
                :placeholder="getTranslate({ text: 'user.placeholders.instagram' })"
                color="#000"
                @update:value="payload.instagram = $event"
            />
        </div>
        <BaseTextarea
            :value="payload.description"
            :label="getTranslate({ text: 'user.labels.description' })"
            :placeholder="getTranslate({ text: 'user.placeholders.description' })"
            color="#000"
            @update:value="payload.description = $event"
        />

        <div class="actions">
            <BaseButton
              width="10rem"
              background="#F29826"
              background-active="#fda22a"
              color="black"
              color-active="black"
              dotted-color="black"
              dotted-color-active="black"
              :uppercase="true"
              :bold="true"
              @clicked="updateProfile()"
            >
              {{ getTranslate({ text: "common.labels.save" }) }}
            </BaseButton>
        </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.profile-page {
  display: flex;
  min-height: 61vh;
  flex-direction: column;
  padding-block: 2rem;

  .avatar {
    margin-block-end: 2rem;

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: min-content;
        gap: 1rem;
    }

    .send-file {
        display: flex;

        input {
            display: none;
        }

        label {
            cursor: pointer;
            background: #e8e8e8;
            padding-block: 0.5rem;
            padding-inline: 1.5rem;
            border-radius: 0.25rem;
            border: 2px dotted #646464;
        }
    }
  }

  .group {
    display: flex;
    flex-direction: row;
  }
}

@media screen and (max-width: 800px) {
    .profile-page {
        .group {
            flex-direction: column;
        }
    }
}
</style>
