import { get, post, put } from "../rest";
import { getLanguage } from "@/common/utils/texts";
import { getUser } from "@/common/services/users";

import type { UserType, ProfileType } from "@/common/types/members";

export interface MemberType {
    id: number;
    member_id: string;
    profile_id: string;
    type: string;
    login: string;
    email: string;
    active: boolean;
    created: string;
    updated: string;
}

function getHeaders() {
    const { token, id } = getUser() ?? {};

    return {
        "language": getLanguage(),
        "token": token,
        "id": id,
    };
}

export async function memberList(data: { page: number; limit?: number; search?: string }) {
    const { page, search, limit } = data;
    return await get(
        `admin/members?page=${page || 0}&search=${search || ''}&limit=${limit || 0}`,
        getHeaders()
    );
}

export async function memberFetch(member_id: string) {
    return await get(`admin/members/${member_id}`, getHeaders());
}

export async function memberAdd(data: UserType | ProfileType ) {
    return await post("admin/members", data, getHeaders());
}

export async function memberUpdate(member_id: string, data: UserType | ProfileType ) {
    return await put(`admin/members/${member_id}`, data, getHeaders());
}
