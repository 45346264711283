<script setup lang="ts">
import { ref } from "vue";
import { useRouter } from "vue-router";
import { MD5 } from "crypto-js";
import store from "@/store";

import MemberForm from "./components/member-form.vue";

import { toast } from "@/common/utils/toast";

import { memberAdd } from "@/common/api/rest/members";

import type { UserType, ProfileType } from "@/common/types/members";

const router = useRouter();

const emptyUser = {
    login: "",
    email: "",
    type: "",
    active: 1,
    password: "",
};

const emptyProfile = {
    first_name: "",
    last_name: "",
    social_name: "",
    birthdate: "",
};

const user = ref<UserType>({ ...emptyUser });
const profile = ref<ProfileType>({ ...emptyProfile });

function addMember() {
    store.commit("SET_LOADING", true);
    if (user.value.password)
        user.value.password = MD5(user.value.password).toString();

    memberAdd({ ...user.value, ...profile.value })
        .then((response) => {
            const { success, data } = response;

            if (success) {
                toast({ message: data.message, type: "success" });
                setTimeout(() => {
                    router.push('/members');
                }, 2000);
            }
            else
                toast({ message: data.message, type: "error" });
        })
        .catch(() => {
            toast({ message: "Erro interno do servidor", type: "error" });
        })
        .finally(() => {
            store.commit("SET_LOADING", false);
        })
}

</script>

<template>
    <section class="credentials-page">
        <h2 class="uppercase">
            ADICIONAR USUÁRIO
        </h2>
        <div class="content">
            <member-form
                :user="user"
                :profile="profile"
                @update:user="user = $event"
                @update:profile="profile = $event"
                @save:values="addMember()"
                @back="router.push('/members')"
            />
        </div>
    </section>
</template>

<style lang="scss" scoped>
.credentials-page {
    display: flex;
    min-height: 63vh;
    flex-direction: column;
    padding-block: 2rem;

    .content {
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin-block-start: 1rem;
    }
}
</style>
