<script setup lang="ts">
import { onMounted, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import store from "@/store";

import { getDate, getHour } from "@/common/utils/dates";
import CredentialForm from "./components/credential-form.vue";

import { toast } from "@/common/utils/toast";

import { credentialGet, credentialCheckin } from "@/common/api/rest/credentials";
import { userFetchAvatar } from "@/common/api/rest/users";

import { BaseAvatar } from "@/components";
import moment from "moment";

const router = useRouter();
const route = useRoute();

const typesList = {
    alley: "Artist Alley",
    cosplay: "Cosplay",
    influencer: "Influencer",
    media: "Imprensa",
    shopkeeper: "Lojista",
};

const userData = ref();
const blocked = ref(false);

const avatar = ref<string | null>(null);

function fetchAvatar(id: string) {
    userFetchAvatar(id)
        .then((response) => {
            if (response.success) {
                avatar.value = response.data.file;
            }
        });
}

function checkBlock() {
    const { check_in } = userData.value;

    const now = moment().format("YYYY-MM-DD");

    check_in?.forEach((item: string) => {
        if (item.includes(now))
            blocked.value = true;
    });
}

function checkIn() {
    if (blocked.value)
        return;

    store.commit("SET_LOADING", true);
    credentialCheckin(userData.value.member_id)
        .then((response) => {
            const { success, data } = response;

            if (success) {
                fechMember();
            }
            else
                toast({ message: data.message, type: "error" });
        })
        .catch(() => {
            toast({ message: "Erro interno do servidor", type: "error" });
        })
        .finally(() => {
            store.commit("SET_LOADING", false);
        })
}

function fechMember() {
    store.commit("SET_LOADING", true);
    credentialGet(route.params.member_id as string)
        .then((response) => {
            const { success, data } = response;

            if (success) {
                userData.value = data;
                fetchAvatar(data.member_id);
                checkBlock();
            }
            else
                toast({ message: data.message, type: "error" });
        })
        .catch(() => {
            toast({ message: "Erro interno do servidor", type: "error" });
        })
        .finally(() => {
            store.commit("SET_LOADING", false);
        })
}

onMounted(() => {
    fechMember();
})
</script>

<template>
    <section class="page">
        <h2 class="uppercase">
            CHECK-IN CREDENCIAL
        </h2>
        <section class="content">
            <section v-if="userData" class="box-user">
                <div class="header">
                    <div class="avatar">
                        <base-avatar :image="avatar" size="180px" />
                    </div>
                    <div class="infos">
                        <div class="line">
                            <label>NOME: </label>
                            <div class="value">
                                {{ userData.profile.first_name }} {{ userData.profile.last_name }}
                            </div>
                        </div>
                        <div class="line">
                            <label>NOME ARTÍSTICO: </label>
                            <div class="value">
                                {{ userData.profile.social_name }}
                            </div>
                        </div>
                        <div class="line">
                            <label>RG: </label>
                            <div class="value">
                                {{ userData.rg }}
                            </div>
                        </div>
                        <div class="line">
                            <label>E-MAIL: </label>
                            <div class="value">
                                {{ userData.email }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="separate" />

                <div class="credential">
                    <div class="header">
                        <div class="infos">
                            <div class="line">
                                <label>CREDENCIAL: </label>
                                <div class="value">
                                    {{ typesList[userData.type as never]?.toUpperCase() }}
                                </div>
                            </div>
                        </div>
                        <div class="actions">
                            <button class="check-in" :disabled="blocked" @click="checkIn()">
                                CHECK-IN
                            </button>
                        </div>
                    </div>
                </div>

                <div class="separate" />

                <div class="checked">
                    <h2 class="uppercase">
                        PRESENÇA
                    </h2>
                    <template v-for="(item, index) in userData?.check_in" :key="index">
                        <div class="item">
                            <div>
                                {{ getDate(item) }}
                            </div>
                            <div>
                                {{ getHour(item) }}
                            </div>
                        </div>
                    </template>
                    <template v-if="(userData?.check_in?.length || 0) === 0">
                        <div class="empty-data">
                            USUÁRIO AINDA NÃO COMPARECEU AO EVENTO!
                        </div>
                    </template>
                </div>
            </section>
        </section>
    </section>
</template>

<style lang="scss" scoped>
.page {
    display: flex;
    min-height: 63vh;
    flex-direction: column;
    padding-block: 2rem;

    .separate {
        height: 30px;
        border-block-start: 1px solid black;
        margin-block-start: 30px;
    }

    .content {
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin-block-start: 1rem;
    }


    .box-user {
        display: flex;
        flex-direction: column;
        padding-inline: 25px;
        padding-block: 30px;
        border: 1px solid black;

        .header {
            display: flex;
            flex-direction: row;
            gap: 30px;

            .infos {
                display: flex;
                flex-direction: column;
                gap: 10px;

            }
        }

        .line {
            display: flex;
            flex-direction: column;

            label {
                font-size: 12px;
            }

            .value {
                font-size: 20px;
                font-weight: bold;
                padding-inline-start: 7px;
            }
        }

        .credential {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .header {
                display: flex;
                flex-direction: row;
                gap: 30px;
                justify-content: space-between;

                .value {
                    font-size: 30px;
                }

                .actions {
                    button {
                        cursor: pointer;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 150px;
                        height: 55px;
                        background: black;
                        border: 0;
                        color: white;
                        border-radius: 5px;
                        font-weight: bold;

                        &:hover {
                            background: black;
                        }

                        &:disabled {
                            cursor: default;
                            background: #e8e8e8;
                            color: rgb(75, 75, 75);
                        }
                    }
                }
            }
        }

        .checked {
            display: flex;
            flex-direction: column;
            gap: 15px;

            .item {
                display: flex;
                flex-direction: row;
                gap: 30px;
                background: #000000;
                color: white;
                padding-block: 25px;
                padding-inline: 25px;

                font-weight: bold;
                font-size: 40px;
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .page {
        .box-user {
            .header {
                flex-direction: column;
                
                .infos {
                    gap: 15px;
                }

                .avatar {
                    display: flex;
                    justify-content: center;
                }

                .infos {
                    .line {
                        label {
                            font-size: 11px;
                        }
                        .value {
                            font-size: 18px;
                        }
                    }
                }
            }

            .credential {
                .header {
                    flex-direction: column;

                    .infos {
                        .value {
                            font-size: 30px;
                        }
                    }

                    .actions {
                        display: flex;
                        justify-content: flex-end;
                    }
                }
            }

            .checked {
                .item {
                    padding-block: 20px;
                    padding-inline: 20px;
                    font-size: 25px;
                }
            }
        }
    }
}
</style>
