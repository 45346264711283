<script setup lang="ts">
import { computed, ref } from "vue";
import { getTranslate } from "@/common/utils/texts";

interface OptionsType {
  label: string;
  value: string | number;
  icon?: string | null;
}
interface PropsType {
  label?: string;
  placeholder?: string;
  selected?: string | number | null;
  options?: OptionsType[];
  error?: boolean;
  errorMessage?: string;
  background?: string;
  backgroundActive?: string;
  color?: string;
  colorActive?: string;
  radius?: string;
  dotted?: boolean;
  dottedColor?: string;
  fontSize?: string;
  border?: boolean;
  borderRadios?: string;
  borderColor?: string;
  optionBackground?: string;
  optionBackgroundActive?: string;
  optionColor?: string;
  optionColorActive?: string;
}

const emit = defineEmits(["change:value"]);

const props = withDefaults(defineProps<PropsType>(), {
  error: false,
  background: "#fff",
  backgroundActive: "#e8e8e8",
  color: "#000",
  colorActive: "#000",
  radius: "0.5rem",
  dotted: false,
  dottedColor: "#000",
  fontSize: "1rem",
  optionBackground: "#fff",
  optionBackgroundActive: "#e8e8e8",
  optionColor: "#000",
  optionColorActive: "#000",
  border: true,
  borderColor: "gray",
});

const show = ref(false);

const placeholderValue = computed(() => {
  if (props.selected) {
    const data = (props.options || []).filter(
      (option) => option.value === props.selected
    );

    return data[0]?.label;
  }

  return props.placeholder ?? getTranslate({ text: "common.labels.select" });
});

const styleBorder = computed(() =>
  props.border ? `1px solid ${props.borderColor}` : "1px solid transparent"
);

function toggle() {
  show.value = !show.value;
}

function onSelect(value: string | number) {
  emit("change:value", value);
  toggle();
}
</script>

<template>
  <div class="base-select" :class="{ error: error }">
    <label v-if="label">
      {{ label }}
    </label>
    <div class="select" :class="{ dotted: dotted }" @click="toggle()">
      <template v-if="dotted">
        <div class="dotted">
          <div class="placeholder">
            <span>
              {{ placeholderValue }}
            </span>
            <span v-if="!show" class="pi pi-chevron-down" />
            <span v-else class="pi pi-chevron-up" />
          </div>
        </div>
      </template>
      <template v-else>
        <div class="placeholder">
          <span>
            {{ placeholderValue }}
          </span>
          <span v-if="!show" class="pi pi-chevron-down" />
          <span v-else class="pi pi-chevron-up" />
        </div>
      </template>
    </div>
    <div v-if="error" class="box-message">
      {{ error ? errorMessage : "" }}
    </div>
    <template v-if="show">
      <div class="options shadow">
        <template v-for="(item, index) in options" :key="index">
          <div class="option" @click="onSelect(item.value)">
            <img v-if="item.icon" :src="item.icon" aria-hidden="true" />
            <span>
              {{ item.label }}
            </span>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
$border: v-bind("styleBorder");
.base-select {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(45px + 1.4rem);

  label {
    display: flex;
    font-size: 0.85rem;
    margin-block: 0.4rem;
    margin-inline: 0.8rem;
  }
  .select {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - (0.75rem * 2));
    height: 45px;
    min-height: 45px;
    color: v-bind("color");
    background: v-bind("background");
    padding-inline: 0.75rem;
    border: $border;
    border-radius: v-bind("radius");
    font-size: v-bind("fontSize");

    .placeholder {
      display: flex;
      width: 100%;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 0.5rem;

      img {
        width: 1rem;
      }
    }
  }

  .select:hover {
    background: v-bind("backgroundActive");
    color: v-bind("colorActive");
  }

  .select.dotted {
    padding-block: 0.4rem;
    padding-inline: 0.4rem;

    .dotted {
      border: 2px dotted v-bind("dottedColor");
      padding-block: 0.5rem;
      padding-inline: 0.75rem;
      border-radius: calc(v-bind("radius") - 0.1rem);
    }
  }

  .options {
    position: relative;
    display: flex;
    width: 99%;
    flex-direction: column;
    margin-block-start: 0.2rem;
    background: v-bind("optionBackground");
    border: 1px solid #e8e8e8;
    z-index: 99;

    .option {
      cursor: pointer;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border: none;
      background: transparent;
      padding-block: 0.75rem;
      padding-inline: 0.9rem;
      color: v-bind("optionColor");
      gap: 0.5rem;

      img {
        width: 1.75rem;
      }
    }

    .option:hover {
      background: v-bind("optionBackgroundActive");
      color: v-bind("optionColorActive");
    }
  }
}

.box-message {
  height: 1.25rem;
  font-size: 0.75rem;
  text-align: end;
  padding-block-start: 0.2rem;
  margin-inline: 0.25rem;
  color: var(--red);
}

.base-select.error {
  .select {
    border: 1px solid var(--red);
  }
}
</style>
