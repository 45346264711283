<script setup lang="ts">
import { ref } from "vue";
import { useRouter } from "vue-router";
import store from "@/store";

import CredentialForm from "./components/credential-form.vue";

import { toast } from "@/common/utils/toast";

import { credentialAdd } from "@/common/api/rest/credentials";

const router = useRouter();

const emptyData = {
    member_id: "",
    profile_id: "",
    type: "",
    status: "",
    name: "",
    instagram: "",
    artistic_name: "",
    birthdate: "",
    rg: "",
    phone: "",
    whatsapp: "",
    description: "",
    metadata: null
};

const payload = ref({ ...emptyData });

function addMember() {
    store.commit("SET_LOADING", true);
    credentialAdd(payload.value)
        .then((response) => {
            const { success, data } = response;

            if (success) {
                toast({ message: data.message, type: "success" });
                setTimeout(() => {
                    router.push('/credentials');
                }, 2000);
            }
            else
                toast({ message: data.message, type: "error" });
        })
        .catch(() => {
            toast({ message: "Erro interno do servidor", type: "error" });
        })
        .finally(() => {
            store.commit("SET_LOADING", false);
        })
}

</script>

<template>
    <section class="credentials-page">
        <h2 class="uppercase">
            ADICIONAR CREDENCIAL
        </h2>
        <div class="content">
            <credential-form
                :payload="payload"
                @update:values="payload = $event"
                @save:values="addMember()"
                @back="router.push('/credentials')"
            />
        </div>
    </section>
</template>

<style lang="scss" scoped>
.credentials-page {
    display: flex;
    min-height: 63vh;
    flex-direction: column;
    padding-block: 2rem;

    .content {
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin-block-start: 1rem;
    }
}
</style>
