import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import LoginPage from "@/pages/auth/login-page.vue";
import HomePage from "@/pages/home/home-page.vue";
import ProfilePage from "@/pages/profile/profile-page.vue";
import RecoverPage from "@/pages/auth/recover-page.vue";
import RecoverHashPage from "@/pages/auth/recover-hash-page.vue";
// MEMBERS
import MemberListPage from "@/pages/members/member-list.vue";
import MemberAddPage from "@/pages/members/member-add.vue";
import MemberEditPage from "@/pages/members/member-edit.vue";
// USERS
import UserListPage from "@/pages/users/user-list.vue";
// CONCURSES
import ConcurseListPage from "@/pages/concurses/concurses-list.vue";
// CREDENTIALS
import CredentialsPage from "@/pages/credentials/credentials-list.vue";
import CredentialsAddPage from "@/pages/credentials/credential-add.vue";
import CredentialsCheckPage from "@/pages/credentials/credential-check.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "LoginPage",
    component: LoginPage,
  },
  {
    path: "/recover",
    name: "RecoverPage",
    component: RecoverPage,
  },
  {
    path: "/recover/hash/:hash",
    name: "RecoverHashPage",
    component: RecoverHashPage,
  },
  {
    path: "/home",
    name: "HomePage",
    component: HomePage,
  },
  // CREDENTIALS
  {
    path: "/credentials",
    name: "CredentialsPage",
    component: CredentialsPage,
  },
  {
    path: "/credentials/add",
    name: "CredentialsAddPage",
    component: CredentialsAddPage,
  },
  {
    path: "/credential/chech/:member_id",
    name: "CredentialsCheckPage",
    component: CredentialsCheckPage,
  },
  {
    path: "/profile",
    name: "ProfilePage",
    component: ProfilePage,
  },
  {
    path: "/members",
    name: "MemberListPage",
    component: MemberListPage,
  },
  {
    path: "/members/add",
    name: "MemberAddPage",
    component: MemberAddPage,
  },
  {
    path: "/members/edit/:id",
    name: "MemberEditPage",
    component: MemberEditPage,
  },
  {
    path: "/users",
    name: "UserListPage",
    component: UserListPage,
  },
  {
    path: "/concurses",
    name: "ConcurseListPage",
    component: ConcurseListPage,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
