import { get, post } from "../rest";
import { getLanguage } from "@/common/utils/texts";
import { getUser } from "@/common/services/users";

function getHeaders() {
  const { token, id } = getUser() ?? {};

  return {
    "language": getLanguage(),
    "token": token,
    "id": id,
  };
}

export async function credentialAdd(data: any ) {
  return await post("admin/credentials", data, getHeaders());
}

export async function credentialsList(data: { page: number; limit?: number; search?: string }) {
  const { page, search, limit } = data;
  return await get(
      `admin/credentials?page=${page || 0}&search=${search || ''}&limit=${limit || 0}`,
      getHeaders()
  );
}

export async function credentialGet(member_id: string) {
  return await get(`admin/credentials/${member_id}`, getHeaders());
}

export async function credentialCheckin(member_id: string) {
  return await post("admin/credentials/checkin", { member_id }, getHeaders());
}