export const config = {
  company: {
    name: "Anime Stars",
    year: "2024",
  },
  logotipo: {
    is_local: true,
    primary: "images/logo-primary.png",
    primary_white: "images/logo-primary.png",
    secondary: "images/icon-256x256.png",
    secondary_white: "images/icon-256x256.png",
    icon: "images/icon-256x256.png",
  },
  body: {
    title: "ADM | Anime Stars",
    background: "#ededed",
    desktop: {
      padding: "3rem",
    },
    mobile: {
      padding: "1rem",
    },
    padding: "3rem",
    mobile_size: 800,
  },
};
