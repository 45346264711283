<script setup lang="ts">
import { ref } from "vue";
import trim from "lodash-es/trim";
import store from "@/store";

import { toast } from "@/common/utils/toast";
import { BaseInput, BaseSelect, BaseTextarea } from '@/components';
import { maskDateBR, getDateISO, getDate } from "@/common/utils/dates";

import { memberList } from "@/common/api/rest/members";

const emit = defineEmits(["searching", "update:values", "back", "save:values"]);

const props = defineProps<{
    payload: any
}>();

const types = [
    { label: "Artist Alley", value: "alley"},
    { label: "Cosplay", value: "cosplay"},
    { label: "Influencer", value: "influencer"},
    { label: "Imprensa", value: "media"},
    { label: "Lojista", value: "shopkeeper"},
    { label: "Jurado", value: "jurors"},
];

const statusOptions = [
    { label: "APROVADO", value: "approval" },
    { label: "PENDENTE", value: "pending" },
    { label: "REPROVADO", value: "disapproved" },
];

const search = ref("");

const searchList = ref<any[]>([]);

async function fetchData() {
    store.commit("SET_LOADING", true);
    await memberList({
        page: 0,
        limit: 500,
        search: search.value,
    })
        .then((response) => {
            const { success, data } = response;

            if (success) {
                searchList.value = data.list;
            } else {
                toast({
                    message: "Erro ao carregar a lista",
                    type: "error"
                });
            }
        })
        .catch(() => {
            toast({
                message: "Erro interno do servidor",
                type: "error"
            });
        })
        .finally(() => {
            store.commit("SET_LOADING", false);
        });
}

function searchValue() {
    if (trim(search.value).length === 0)
        return;

        fetchData();
}

function selectUser(data: any) {
    searchList.value = [];
    search.value = "";

    const payload = {
        ...props.payload,
        member_id: data.member_id,
        profile_id: data.profile.profile_id,
        artistic_name: data.profile.social_name,
        name: data.profile.first_name + " " + data.profile.last_name,
    };

    emit("update:values", payload);
}

function updateData(key: string, value: string) {
    const payload = {
        ...props.payload,
        [key]: value,
    };

    emit("update:values", payload);
}
</script>

<template>
    <div class="form">
        <section>
            <h4>USUÁRIO</h4>
            <base-input
                name="search"
                :sufix="true"
                :sufix-click="true"
                :value="search"
                placeholder="Buscar"
                color="#000"
                background="#fff"
                @update:value="search = $event"
                @click:sufix="searchValue()"
            >
                <template v-slot:sufix>
                    <img src="@/assets/icons/black/search.png" />
                </template>
            </base-input>

            <section v-if="searchList.length > 0" class="box-search">
                <div class="box">
                    <template v-for="(item, index) in searchList" :key="index">
                        <div class="item" @click="selectUser(item)">
                            <div class="name">
                                {{ item.profile.first_name }} {{ item.profile.last_name }}
                            </div>
                            <div class="email">
                                {{ item.email }}
                            </div>
                        </div>
                    </template>
                </div>
            </section>

            <section class="separate" />

            <base-select
                label="Tipo de Credencial"
                :selected="payload.type"
                :options="types"
                placeholder="Informe o tipo de credencial"
                @change:value="updateData('type', $event)"
            />

            <base-select
                label="Status"
                :selected="payload.status"
                :options="statusOptions"
                placeholder="Status credencial"
                @change:value="updateData('status', $event)"
            />

            <base-input
                name="name"
                label="name"
                :value="payload.name"
                placeholder="Informe o nome"
                background="white"
                @update:value="updateData('name', $event)"
            />

            <base-input
                name="instagram"
                label="Instagram"
                :value="payload.instagram"
                placeholder="Informe o instagram"
                background="white"
                @update:value="updateData('instagram', $event)"
            />

            <base-input
                name="artistic_name"
                label="Nome Artístico"
                :value="payload.artistic_name"
                placeholder="Nome Artístico"
                background="white"
                @update:value="updateData('artistic_name', $event)"
            />


            <base-input
                name="birthdate"
                label="Aniversário"
                :value="payload.birthdate"
                placeholder="Nascimento"
                background="white"
                @update:value="updateData('birthdate', $event)"
            />


            <base-input
                name="RG"
                label="RG"
                :value="payload.rg"
                placeholder="RG"
                background="white"
                @update:value="updateData('rg', $event)"
            />
    
            <base-input
                name="phone"
                label="Telefone"
                :value="payload.phone"
                placeholder="Telefone"
                background="white"
                @update:value="updateData('phone', $event)"
            />


            <base-input
                name="whatsapp"
                label="Whatsapp"
                :value="payload.whatsapp"
                placeholder="Whatsapp"
                background="white"
                @update:value="updateData('whatsapp', $event)"
            />

            <base-input
                name="description"
                label="Whatsapp"
                :value="payload.whatsapp"
                placeholder="Descrição"
                background="white"
                @update:value="updateData('whatsapp', $event)"
            />

            <base-textarea
                label="Descrição"
                placeholder="Descrição"
                :value="payload.description"
                background="white"
                @update:value="updateData('description', $event)"
            />
        </section>

        <section class="actions">
            <button class="back" @click="emit('back')">
                VOLTAR
            </button>
            <button class="save" @click="emit('save:values')">
                SALVAR
            </button>
        </section>
    </div>
</template>

<style lang="scss" scoped>
.form {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
    gap: 8px;

    section {
        display: flex;
        width: 98%;
        flex-direction: column;
        gap: 15px;
    }

    .separate {
        height: 30px;
        border-block-start: 1px solid black;
        margin-block-start: 30px;
    }

    .actions {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-block-start: 15px;
        gap: 15px;

        button {
            cursor: pointer;
            width: fit-content;
            height: 45px;
            padding-block: 5px;
            padding-inline: 20px;
            border: 1px solid transparent;
            border-radius: 8px;
            font-weight: bold;
        }

        .back {
            background: #e8e8e8;
            
            &:hover {
                background: #bebebe;
            }
        }

        .save {
            background: var(--primary);
            padding-inline: 35px;

            &:hover {
                background: var(--primary-hover);
            }
        }
    }

    .box-search {
        background: #e8e8e8;
        width: calc(100% - 30px);
        padding: 15px;
        max-height: 200px;
        overflow-y: auto;

        .box {
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: 8px;

            .item {
                cursor: pointer;
                display: flex;
                flex-direction: column;
                user-select: none;
                padding-inline: 15px;
                padding-block: 5px;
                background: white;
                border: 1px solid transparent;

                &:hover {
                    border: 1px solid black;
                }

                .email {
                    font-size: 13px;
                }
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .form {
        width: 99%;
    }
}
</style>