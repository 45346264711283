export const login = {
  titles: {
    login: "Acessar Conta",
    recover: "Recuperar Senha",
    register: "Cadastrar",
    new_password: "Nova senha",
  },
  labels: {
    recover: "Recuper senha!",
    dont_account: "Não tem conta?",
    already_account: "Já tem conta?",
  },
  placeholders: {
    nickname: "Nick de login",
    login: "Apelido ou e-mail",
    email: "E-mail cadastrado",
    password: "Senha",
    confirm_password: "Confirme a senha",
    yout_email: "Informe seu e-mail",
    name: "Nome completo",
    birthdate: "Nascimento",
  },
};
