<script setup lang="ts">
import { computed, ref, watch } from "vue";
import trim from "lodash-es/trim";
import get from "lodash-es/get";

import { BaseInput, BaseButton } from "@/components";

export interface TableHeaderType {
    key: string;
    label: string;
}

const props = withDefaults(defineProps<{
    header: TableHeaderType[];
    data: any[];
    page?: number;
    pages?: number;
    limit?: number;
    total?: number;
    searched?: number;
    options?: boolean;
    view?: boolean;
    edit?: boolean;
    del?: boolean;
}>(), {
    page: 0,
    pages: 0,
    limit: 10,
    total: 0,
    searched: 0,
    options: false,
    view: false,
    edit: false,
    del: false,
});

const emit = defineEmits([
    "searching",
    "btn:add",
    "btn:view",
    "btn:edit",
    "btn:del",
    "change:page"
]);

const search = ref("");

const totalSearch = computed(() => {
    const { limit, page, searched } = props;
    if (page > 0)
        return (page + 1) * limit;

    return searched;
});

function searchValue() {
    if (trim(search.value).length === 0)
        return;

    emit("searching", search.value);
}

function changePage(event: Event) {
    const value = event?.target?.value || 0;
    const sanitize = String(value)?.replace(/\D/g, '');

    if (trim(sanitize) === "") {
        emit("change:page", 0);
        return;
    }
    
    if (parseInt(sanitize) < 0) {
        emit("change:page", 0);
        return;
    }

    if (parseInt(sanitize) >= props.pages) {
        emit("change:page", props.pages);
        return;
    }

    emit("change:page", (parseInt(sanitize) -1));
}

function nextPage() {
    const { page, pages } = props;

    if (page >= pages)
        return;

    emit("change:page", (page + 1));
}

function beforePage() {
    const { page, pages } = props;

    if (page <= 0)
        return;

    emit("change:page", (page - 1));
}

function btnView(option: any) {
    emit("btn:view", option);
}

function btnEdit(option: any) {
    emit("btn:edit", option);
}

function btnDel(option: any) {
    emit("btn:del", option);
}

watch(() => search.value, () => {
    if (trim(search.value).length === 0)
        emit("searching", search.value);
});
</script>

<template>
    <div class="base-table">
        <div class="header">
            <div class="search">
                <base-input
                    name="search"
                    :sufix="true"
                    :sufix-click="true"
                    :value="search"
                    placeholder="Buscar"
                    color="#000"
                    background="#fff"
                    @update:value="search = $event"
                    @click:sufix="searchValue()"
                >
                    <template v-slot:sufix>
                        <img src="@/assets/icons/black/search.png" />
                    </template>
                </base-input>
            </div>
            <div class="add">
                <base-button
                    background="black"
                    background-active="black"
                    color="white"
                    color-active="white"
                    :bold="false"
                    @clicked="emit('btn:add')"
                >
                    ADICIONAR
                </base-button>
            </div>
        </div>
        <div class="infos">
            <div class="total">
                Mostrando <strong>{{ totalSearch }}</strong> de <strong>{{ total || 0 }}</strong>
            </div>
            <div class="pages">
                Página <strong>{{ (page + 1) }}</strong> de <strong>{{ pages }}</strong>
            </div>
        </div>
        <table>
            <thead>
                <tr>
                    <template v-for="(item) in header" :key="item.key">
                        <th>{{ item.label }}</th>
                    </template>
                    <th v-if="options"></th>
                </tr>
            </thead>
            <tbody>
                <template v-for="(item, index) in data" :key="index">
                    <tr :class="{ 'bg-white': (index % 2 === 0) }">
                        <template v-for="(head) in header" :key="head.key">
                            <td>
                                {{ get(item, head.key, "") }}
                            </td>
                        </template>
                        <td v-if="options" width="120px">
                            <div class="options">
                                <img
                                    v-if="view"
                                    src="@/assets/icons/black/eye-open.png"
                                    @click="btnView(item)"
                                />
                                <img
                                    v-if="edit"
                                    src="@/assets/icons/black/edit.png"
                                    @click="btnEdit(item)"
                                />
                                <img
                                    v-if="del"
                                    src="@/assets/icons/black/trash.png"
                                    @click="btnDel(item)"
                                />
                            </div>
                        </td>
                    </tr>
                </template>
                <tr v-if="(data || []).length === 0">
                    <td class="empty" :colspan="(((header || []).length || 1) + (options ? 1 : 0))">
                        Não há resultados para serem exibidos
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="pagination">
            <div class="arrows">
                <img
                    src="@/assets/icons/black/arrow-min-left.png"
                    @click="beforePage()"
                />
                <input
                    name="page"
                    :value="(page + 1)"
                    @change="changePage($event)"
                >
                <img
                    src="@/assets/icons/black/arrow-min-right.png"
                    @click="nextPage()"
                />
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.base-table {
    display: flex;
    flex-direction: column;
    flex: 15px;

    table {
        border: 0.5px solid #d8d8d8;
        border-spacing: 0;

        tr {
            background: #f1f1f1;
        }

        th {
            text-align: start;
            padding-inline: 8px;
            padding-block: 3px;
            background: #d8d8d8;
            border: 0.5px solid #f1f1f1;
        }

        td {
            text-align: start;
            padding-inline: 8px;
            border: 0.5px solid #d8d8d8;
        }

        td.empty {
            background: #fff;
            padding-block: 10px;
        }
    }

    .header {
        display: grid;
        grid-template-columns: 2fr 1fr;
        justify-content: space-between;
        gap: 1rem;

        .add {
            display: flex;
            justify-content: flex-end;
            align-items: flex-start;
        }
    }

    .infos {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-block-start: 18px;
        padding-block-end: 10px;

        .total {
            font-size: 15px;
        }
    }

    .options {
        display: flex;
        width: 120px;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 8px;

        img {
            cursor: pointer;
            user-select: none;
            width: 30px;
            opacity: 0.5;

            &:hover {
                opacity: 1;
            }
        }
    }

    .pagination {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        padding-block: 10px;


        .arrows {
            display: grid;
            width: 150px;
            grid-template-columns: 30px 70px 30px;
            gap: 10px;
            align-items: center;
            justify-content: center;

            input {
                height: 30px;
                font-size: 1.2rem;
                font-weight: bold;
                text-align: center;
            }

            img {
                user-select: none;
                cursor: pointer;
            }
        }
    }

    .bg-white {
        background: #fff;
    }
}
</style>