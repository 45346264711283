<script setup lang="ts">
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import store from "@/store";

import { BaseTable } from "@/components";

import { toast } from "@/common/utils/toast";

import { memberList } from "@/common/api/rest/members";

import type { MemberType } from "@/common/api/rest/members";

const router = useRouter();

const list = ref<MemberType[]>([]);
const page = ref(0);
const limit = ref(10);

const search = ref("");
const pages = ref(0);
const count = ref(0);
const total = ref(0);

const tableHeader = [
    { key: "id", label: "ID" },
    { key: "type", label: "TIPO" },
    { key: "name", label: "NOME" },
    { key: "email", label: "E-MAIL" },
];

async function fetchMembers() {
    store.commit("SET_LOADING", true);
    await memberList({
        page: page.value,
        limit: limit.value,
        search: search.value,
    })
        .then((response) => {
            const { success, data } = response;

            if (success) {
                total.value = data.infos.total;
                pages.value = data.infos.pages;
                count.value = data.infos.count;

                list.value = data.list
                    .map((item: any) => {
                        return {
                            ...item,
                            name: `${item.profile.first_name} ${item.profile.last_name}`,
                        }
                    });
            } else {
                toast({
                    message: "Erro ao carregar a lista",
                    type: "error"
                });
            }
        })
        .catch(() => {
            toast({
                message: "Erro interno do servidor",
                type: "error"
            });
        })
        .finally(() => {
            store.commit("SET_LOADING", false);
        });
}

function searchMembers(value: string) {
    search.value = value;
    fetchMembers();
}

function fetchPage(value: number) {
    page.value = value;
    fetchMembers();
}

function editMember(member: MemberType) {
    console.log("editMember", member);
    router.push(`/members/edit/${member.member_id}`);
}

function delMember(member: MemberType) {
    console.log("delMember", member);
}

onMounted(() => {
    fetchMembers();
});
</script>

<template>
    <section class="credentials-page">
        <h2 class="uppercase">
            USUÁRIOS CADASTRADOS
        </h2>
        <div class="list">
            <BaseTable
                :header="tableHeader"
                :data="list"
                :total="total"
                :searched="count"
                :page="page"
                :pages="pages"
                :limit="limit"
                :options="true"
                :edit="true"
                :del="true"
                @searching="searchMembers"
                @change:page="fetchPage"
                @btn:add="router.push('/members/add')"
                @btn:edit="editMember($event)"
                @btn:del="delMember($event)"
            />
        </div>
    </section>
</template>

<style lang="scss" scoped>
.credentials-page {
    display: flex;
    min-height: 63vh;
    flex-direction: column;
    padding-block: 2rem;

    .list {
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin-block-start: 1rem;
    }
}
</style>
