import { get } from "../rest";
import { getLanguage } from "@/common/utils/texts";
import { getUser } from "@/common/services/users";

function getHeaders() {
    const user = getUser();
    return {
        "Content-Type": "application/json",
        "language": getLanguage(),
        "token": user?.token,
        "id": user?.id,
    };
}

export async function usersList(data: { page: number; limit?: number; search?: string }) {
    const { page, search, limit } = data;
    return await get(
        `admin/users?page=${page || 0}&search=${search || ''}&limit=${limit || 0}`,
        getHeaders()
    );
}